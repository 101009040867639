import {Footer} from "../../Components/footer";
import React from "react";
import {ContentContact} from "./components";
import {BackgroundMultipleImg} from "../../Components/background";

export function Contact() {
    return (
        <>
            <BackgroundMultipleImg/>
            <div
                className={"z-50 w-full min-h-screen flex flex-col justify-between items-center gap-5 bg-[#022855] bg-opacity-75 "}>
                <ContentContact/>
                <Footer/>
            </div>
        </>

    );
}
