import {Footer} from "../../Components/footer";
import React from "react";
import {ContentLegalNotices} from "./components";

export default function LegalNotices() {
    return (
        <div className={"w-full min-h-screen flex flex-col justify-between items-center gap-5"}>
          <ContentLegalNotices/>
          <Footer/>
        </div>
    );
}
