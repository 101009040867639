import {ComposantProject} from "../components";
import React from "react";
import {
    AskInfos,
    Bandeau,
    ContentImgLeft,
    ContentImgRight,
    ProgramTitle
} from "./components";
import {Footer} from "../../../Components/footer";
import {BackgroundMultipleImg} from "../../../Components/background";

export const ValuesLarmorVillage: Record<string, any> = {
    'TITLE': "L'Armor Village",
    'SOUSTITLE': 'Le nouveau cœur de Kerblaisy',
    'STATUT': 'RESIDENCE LIVREE',
    'URL': '/nos-programmes/larmorvillage',
    'LOCALISATION': 'Larmor-Plage',
    'LOGO': '../Programs/LarmorVillage/1.jpg',
    'ONGLET1': 'La résidence',
    'ADDRESS': '3 Rue du Minio',
    'ADDRESSCOUNTRY': '56260 LARMOR-PLAGE',
    'LAT': 47.718027423054124,
    'LONG': -3.383279322747116,
    'TEXTONGLET1':
        <p>
            L'Armor Village répond à toutes vos attentes. Ses services et commerces, notamment la Halle Bio, apportent
            toutes les commodités à proximité.
            <br/>
            L'Armor Village a été soigneusement conçue par le Cabinet d'architecture Sadou Architectes pour bénéficier
            des avantages que représentent les services et les commerces d'un centre ville sans en subir les nuisances.
        </p>,
    'IMGONGLET1': '../Programs/LarmorVillage/1.jpg',
    'ONGLET2': 'Les appartements',
    'TEXTONGLET2':
        <p>
            La résidence s'implante sur plus de 120 mètres de façade sur rue. Les appartements desservis par ascenseur,
            depuis le sous-sol jusqu'au dernier étage, bénéficient tous de larges terrasses, balcons ou jardins exposés
            pour la plupart au Sud ou à l'Ouest.
            <br/>
            Elle offre des appartements du T2 au T5, lumineux et soigneusement agencés dans un cadre de vie privilégié.
            Ascenseur et garages boxés en sous-sol.
        </p>,
    'IMGONGLET2': '../Programs/LarmorVillage/2.jpg',
    'ONGLET3': 'Prestations',
    'TEXTONGLET3': <div className={"flex flex-col"}><p>L'exigence est la ligne directrice. Les prestations haut de gamme
        et le goût du détail sont la marque de la résidence afin d'offrir la meilleure qualité de vie aux futurs
        résidents.</p>
        <p>Des prestations de Standing :</p>
        <ul className={"list-disc list-inside"}>
            <li>Carrelage grand format 60X60 et faiences Porcelanosa</li>
            <li>Parquets stratifiés Porcelanosa</li>
            <li>Meuble de SDB Porcelanosa avec applique et miroir</li>
            <li>Robinetterie Grohe</li>
            <li>WC suspendu</li>
            <li>Chaudière à gaz Viessmann ou équivalent</li>
            <li>Grandes terrasses, balconss ou jardins privatifs pour tous les appartements</li>
            <li>Larges baies vitrées aluminium</li>
            <li>Pack domotique sur demande</li>
        </ul>
    </div>,
    'IMGONGLET3': '../Programs/LarmorVillage/3.jpg',
    'ONGLET4': 'Larmor-Plage (56)',
    'TEXTONGLET4':
        <p>
            Idéalement située en Bretagne Sud, jouxtant deux des quatre grandes villes du Morbihan, aux portes de
            Lorient, la ville aux 5 ports, Larmor-Plage jouit d'une image séduisante. Prisée des amoureux de la
            Bretagne, réputée pour son charme authentique et la beauté de ses plages, Larmor-Plage a de quoi faire rêver
            : la mer, le port de plaisance, les restaurants, les commerces, tous les services à proximité immédiate et
            avec pour horizon l'ile de Groix.
            <br/>
            C'est ici que nous vous invitons à jeter l'ancre, un lieu unique de calme et de détente, ce mélange de
            bien-être et d'authencité ou la douceur de vivre s'apprécie pour les vacances ou tout au long de l'année.
        </p>,
    'IMGONGLET4': '../Villes/larmor.jpg',
        'GALLERY' : new Set(['../Programs/LarmorVillage/1.jpg', '../Programs/LarmorVillage/2.jpg', '../Programs/LarmorVillage/3.jpg']),
    'PLAQUETTE' : '../Programs/LarmorVillage/plaquette.pdf'
};

export const PreviewLarmorVillage = ({detailHover}: { detailHover: boolean }) => {
    return (
        <ComposantProject
            title={ValuesLarmorVillage['TITLE']}
            statut={ValuesLarmorVillage['STATUT']}
            localisation={ValuesLarmorVillage['LOCALISATION']}
            logo={ValuesLarmorVillage['LOGO']}
            url={ValuesLarmorVillage['URL']}
            detailHover={detailHover}
        />
    );
}

export const PageLarmorVillage = () => {
    return (
        <>
            <BackgroundMultipleImg/>
            <div className={"z-50 w-full min-h-screen flex flex-col justify-between items-center pt-10"}>
                <div className={"w-full flex flex-col pt-20 bg-white"}>
                    <ProgramTitle
                        title={ValuesLarmorVillage['TITLE']}
                        sousTitle={ValuesLarmorVillage['SOUSTITLE']}
                    />
                    <ContentImgLeft
                        title={ValuesLarmorVillage['ONGLET1']}
                        text={ValuesLarmorVillage['TEXTONGLET1']}
                        img={ValuesLarmorVillage['IMGONGLET1']}
                        listImg={ValuesLarmorVillage['GALLERY']}
                        plaquette={ValuesLarmorVillage['PLAQUETTE']}
                    />
                    <ContentImgRight
                        title={ValuesLarmorVillage['ONGLET2']}
                        text={ValuesLarmorVillage['TEXTONGLET2']}
                        img={ValuesLarmorVillage['IMGONGLET2']}
                    />
                    <ContentImgLeft
                        title={ValuesLarmorVillage['ONGLET3']}
                        text={ValuesLarmorVillage['TEXTONGLET3']}
                        img={ValuesLarmorVillage['IMGONGLET3']}
                    />
                    <ContentImgRight
                        title={ValuesLarmorVillage['ONGLET4']}
                        text={ValuesLarmorVillage['TEXTONGLET4']}
                        img={ValuesLarmorVillage['IMGONGLET4']}
                    />
                </div>
                <div className={"w-full flex flex-col bg-opacity-75 bg-[#022855] "}>
                    <Bandeau/>
                    <AskInfos
                        lat={ValuesLarmorVillage['LAT']}
                        long={ValuesLarmorVillage['LONG']}
                        country={ValuesLarmorVillage['ADDRESSCOUNTRY']}
                        adress={ValuesLarmorVillage['ADDRESS']}
                        currentPage={ValuesLarmorVillage['TITLE']}
                    />
                    <Footer/>
                </div>
            </div>
            </>
    );
}