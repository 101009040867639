import {ComposantProject} from "../components";
import React from "react";

export const ValuesLQI: Record<string, any> = {
    'TITLE': 'Lorient Quai des Indes',
    'STATUT': 'DEMARRAGE DES TRAVAUX',
    'URL': 'https://myprojet.epsilon3d.fr/p/imwest-quai-des-indes/',
    'LOCALISATION': 'Lorient',
    'LOGO': '../Programs/LQI/1.jpeg'
};

export const PreviewLQI = ({detailHover}:{detailHover:boolean}) => {
    return (
        <ComposantProject
            title={ValuesLQI['TITLE']}
            statut={ValuesLQI['STATUT']}
            localisation={ValuesLQI['LOCALISATION']}
            logo={ValuesLQI['LOGO']}
            url={ValuesLQI['URL']}
            detailHover={detailHover}
        />
    );
}