import React from "react";
import {Image} from "react-bootstrap";

export function ContentGuarantees() {
    return (
        <div
            className="flex flex-col items-center gap-5 w-full h-full pt-10 ">
            <div className="flex flex-col items-center gap-4 w-full pt-20">
                <h2 className={"text-[#022855] p-1 font-semibold lg:text-5xl md:text-4xl text-3xl text-center"}>Nos garanties</h2>
                <div className={"bg-[#022855] h-1 w-52 rounded-full"}></div>
                <h3 className={"font-semibold text-xl text-center text-[#022855]"}>pour un service immobilier haut de
                    gamme</h3>
            </div>
            <div className={"flex flex-col gap-5 md:w-[80vw] sm:w-[90vw]"}>
                <div className={"grid grid-cols-1 md:grid-cols-2 gap-3 "}>
                    <div className={"text-justify"}>
                        <span>Des garanties sont obligatoires : </span>
                        <span className={"font-semibold"}>la Garantie Dommage Ouvrage</span>
                        <span> et la </span>
                        <span className={"font-semibold"}>Garantie de Fin d'Achèvement</span>
                        <span> (GFA). Cette dernière permet de donner la certitude à l'acquéreur qu'en cas de défaillance du promoteur,
                    son immeuble sera achevé. Elle est encadrée par le </span>
                        <span
                            className={"font-semibold"}>code de la contruction et de l'habitation</span>
                        <span> par les articles R-261-17 à 24. Nos GFA sont toutes délivrées par des organismes bancaires nationaux. </span>
                        <span>En complément de ces garanties obligatoires, Horizon Grand Large est couvert par des garanties complémentaires.</span>
                    </div>
                    <div className={"text-center flex flex-col gap-3"}>
                        <div className={"flex flex-col gap-3 text-white"}>
                            <div className={"flex flex-col gap-3 bg-[#022855] p-3"}>
                                <h3 className={"font-semibold text-lg text-center text-white"}>Garanties
                                    obligatoires</h3>
                                <div className={"flex flex-col gap-1"}>
                                    <span>Dommage - Ouvrage (DO)</span>
                                    <span>Garantie fin d'achèvement (GFA)</span>
                                </div>
                            </div>
                            <div className={"flex flex-col gap-3 bg-[#022855] p-3"}>
                                <h3 className={"font-semibold text-lg text-center"}>Garanties
                                    facultatives</h3>
                                <div className={"flex flex-col gap-1"}>
                                    <span>Constructeur non réalisateur (CNR)</span>
                                    <span>Tous risques chantier (TRC)</span>
                                    <span> Responsabilité Maître d'ouvrage (RCMO)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Image src={"../plan.jpg"} className={"rounded-none object-cover"}/>
                <div className={"text-justify"}>
                    <span>Acheter dans le neuf, c'est l'avantage de bénéficier de </span>
                    <span className={"font-semibold"}>garanties légales de construction</span>
                    <span>, de </span>
                    <span className={"font-semibold"}>frais de notaire réduits</span>
                    <span>, d'un </span>
                    <span className={"font-semibold"}>financement à taux préférentiel</span>
                    <span> ou d'une </span>
                    <span
                        className={"font-semibold"}>défiscalisation pour un investissement locatif</span>
                    <span>, et la possibilité de personnaliser certains aménagements pour vivre pleinement et sereinement votre acquisition ou votre nouvel investissement.</span>
                </div>
            </div>


        </div>
    );
}