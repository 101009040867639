import { Link } from 'react-router-dom';
import {Footer} from "../../Components/footer";

export default function NoMath() {
    return (
        <div
            className={'w-full h-screen flex flex-col justify-between items-center'}
        >
            <div className={'w-full h-full flex justify-center items-center'}>
                <div className={'flex flex-col justify-center items-center gap-1'}>
                    <h1 className="m-4 text-6xl font-extrabold">Oops !</h1>
                    <p className="text-lg">La page demandé n'existe pas.</p>
                    <Link
                        className="m-10 text-white font-medium rounded-2 bg-[#022855] p-2 hover:bg-[#022855]/75"
                        to="/"
                    >
                        Retour à l'accueil
                    </Link>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
