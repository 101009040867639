import React from 'react';
import Toast from 'react-bootstrap/Toast';
import {ToastContainer} from "react-bootstrap";

export const Alert = ({title, content, onCloseCalled} : {title: string; content: string, onCloseCalled : () => void}) => {
    return (
        <ToastContainer
            className="p-3 fixed bottom-0 right-0"
            style={{ zIndex: 1 }}
        >
            <Toast className={"rounded-none"} onClose={onCloseCalled} show={true} delay={10000} autohide animation={true}>
                <Toast.Header className={"border-none justify-between bg-opacity-25 bg-white"}>
                    <span className={"font-semibold font-primary"}>{title}</span>
                </Toast.Header>
                <Toast.Body className={"bg-opacity-25 bg-white text-xs"}>{content}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
}