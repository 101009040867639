import React from "react";
import {FormContact} from "../../Components/form-contact";

export function ContentContact() {
    return (
        <div
            className="z-50 flex items-center justify-center w-full h-full flex-grow-1 pt-20">
            <div
                className={"bg-[#022855] p-4 flex flex-col items-center gap-4 w-full h-full xl:w-[60vw] lg:w-[70vw] md:w-[80vw] sm:w-[90vw]"}>
                <FormContact isLarge={true} currentPage={"Page de contact"}/>
            </div>
        </div>

    );
}