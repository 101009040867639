import {Link} from "react-router-dom";

export function Footer() {
    return (
        <footer className="w-full bg-[#022855] bottom-0" role="banner">
                <div className="container">
                    <div className="p-4 flex justify-center items-center">
                        <div className={"mb-0 text-sm text-white inline-flex items-center justify-center gap-2"}>
                          <Link to={"/mentions-legales"}>Mentions Légales</Link> - <h3>© Groupe IMWEST</h3>
                        </div>
                    </div>
                </div>
        </footer>
        )
    ;
}



