import {GoogleMap, MarkerF, useLoadScript} from "@react-google-maps/api";

function Map() {
    return (
        <GoogleMap zoom={15} center={{ lat: 47.74679514037264, lng: -3.360814718501311344 }} mapContainerClassName="map-container">
            <MarkerF position={{ lat: 47.74679514037264, lng: -3.360814718501311344 }} />
        </GoogleMap>
    );
}

const MapForProgram = ({lat, long}: {lat: number; long:number}) => {
    return (
        <GoogleMap zoom={15} center={{ lat: lat, lng: long }} mapContainerClassName="map-container-program">
            <MarkerF position={{ lat: lat, lng: long }} />
        </GoogleMap>
    );
}

export function MyMapGoogle () {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyCoT5tz8jYO7qMQbr-EA0CM0NjhYxITrD0",
        version: "weekly"
    });

    if (!isLoaded) return <div>Loading...</div>;

    return <Map />
}

export const MyMapGoogleForProgram = ({lat, long}: {lat: number; long:number}) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyCoT5tz8jYO7qMQbr-EA0CM0NjhYxITrD0",
        version: "weekly"
    });

    if (!isLoaded) return <div>Loading...</div>;

    return <MapForProgram lat={lat} long={long} />
}
