import {Footer} from "../../Components/footer";
import React from "react";
import {ContentGuarantees} from "./components";

export default function Guarantees() {
    return (
        <div className={"w-full min-h-screen flex flex-col justify-between items-center gap-5"}>
            <ContentGuarantees/>
            <Footer/>
        </div>
    );
}
