import {FormEvent} from "react";

export const SUBJECT = "formSubject"
export const CONTACT_EMAIL = "formEmail"
export const NOM_PRENOM = "formLastName"
export const MESSAGE = "formMessage"
export const PHONE_NUMBER = "formNumber"

interface ContactMailDescription {
    subject: string,
    message: string,
    phoneNumber: string,
    firstAndLastName: string,
    contactEmail: string,
    formPage: string
}

export const sendMailContactFromForm = async (
    event: FormEvent<HTMLFormElement>,
    currentPage: string,
    onSuccess: () => void,
    onFail: () => void
) => {
    event.preventDefault();

    const form = event.currentTarget;

    const handleSendMailSuccess = () => {
        form.reset();
        onSuccess();
    }

    const handleSendMailFail = () => {
        onFail();
    }

    if (form.checkValidity()) {
        const data = new FormData(form);

        const mailDescription: ContactMailDescription = {
            subject: data.get(SUBJECT) as string,
            contactEmail: data.get(CONTACT_EMAIL) as string,
            firstAndLastName: data.get(NOM_PRENOM) as string,
            formPage: currentPage,
            message: data.get(MESSAGE) as string,
            phoneNumber: data.get(PHONE_NUMBER) as string,
        }
        await sendEmail(mailDescription, handleSendMailSuccess, handleSendMailFail);

        form.reset()
    }
};

const sendEmail = async (description: ContactMailDescription,
                         onSuccess: () => void,
                         onFail: () => void) => {

    const response = await fetch("https://api.emailjs.com/api/v1.0/email/send", {
        method: "POST",
        redirect: "follow",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
            "service_id": "service_6yt2eqi",
            "template_id": "template_ornzooo",
            "user_id": "B1QN2_3xi2umV_uR-",
            "template_params": {
                "subject": description.subject,
                "firstAndLastName": description.firstAndLastName,
                "contactEmail": description.contactEmail,
                "phoneNumber": description.phoneNumber,
                "originWebPage": description.formPage,
                "message": description.message
            }
        })
    });

    if (response.status >= 200 && response.status < 400) {
        onSuccess()
    } else {
        onFail()
    }
}