import {ComposantProject} from "../components";
import React from "react";
import {AskInfos, Bandeau, ContentImgLeft, ContentImgRight, ProgramTitle} from "./components";
import {Footer} from "../../../Components/footer";
import {BackgroundMultipleImg} from "../../../Components/background";
import {ValuesTyCampus} from "./ty_campus";

export const ValuesQuelisoy: Record<string, any> = {
    'TITLE': 'Résidence Quélisoy',
    'STATUT': 'RESIDENCE LIVREE',
    'URL': '/nos-programmes/quelisoy',
    'LOCALISATION': 'Larmor-Plage',
    'LOGO': '../Programs/Quelisoy/1.jpg',
    'ADDRESS': 'Rue Quélisoy Village',
    'ADDRESSCOUNTRY': '56260 LARMOR-PLAGE',
    'LAT': 47.72497085267978,
    'LONG': -3.3843643608309093,
    'ONGLET1': 'La résidence',
    'TEXTONGLET1': 'La résidence Quélisoy est une copropriété, de 4 logements en locatif social, réalisée sur la commune de Larmor-Plage.',
    'IMGONGLET1': '../Programs/Quelisoy/1.jpg',
    'ONGLET2': 'Larmor-Plage (56)',
    'TEXTONGLET2':
        <p>
            Idéalement située en Bretagne Sud, jouxtant deux des quatre grandes villes du Morbihan, aux portes de
            Lorient, Larmor-Plage jouit d'une image séduisante. Prisée des amoureux de la Bretagne, réputée pour son
            charme authentique et la beauté de ses plages, Larmor-Plage a de quoi faire rêver : la mer, le port de
            plaisance, les restaurants, les commerces, tous les services à proximité immédiate et avec pour horizon
            l'ile de Groix.
            <br/>
            C'est ici que nous vous invitons à jeter l'ancre, un lieu unique de calme et de détente, ce mélange de
            bien-être et d'authencité ou la douceur de vivre s'apprécie pour les vacances ou tout au long de l'année.
        </p>,
    'IMGONGLET2': '../Villes/larmor.jpg',
    'GALLERY': new Set(['../Programs/Quelisoy/1.jpg'
    ])
};

export const PreviewQuelisoy = ({detailHover}: { detailHover: boolean }) => {
    return (
        <ComposantProject
            title={ValuesQuelisoy['TITLE']}
            statut={ValuesQuelisoy['STATUT']}
            localisation={ValuesQuelisoy['LOCALISATION']}
            logo={ValuesQuelisoy['LOGO']}
            url={ValuesQuelisoy['URL']}
            detailHover={detailHover}
        />
    );
}
export const PageQuelisoy = () => {
    return (
        <>
            <BackgroundMultipleImg/>
            <div className={"z-50 w-full min-h-screen flex flex-col justify-between items-center pt-10"}>
                <div className={"w-full flex flex-col pt-20 bg-white"}>
                    <ProgramTitle
                        title={ValuesQuelisoy['TITLE']}
                        sousTitle={ValuesQuelisoy['SOUSTITLE']}
                    />
                    <ContentImgLeft
                        title={ValuesQuelisoy['ONGLET1']}
                        text={ValuesQuelisoy['TEXTONGLET1']}
                        img={ValuesQuelisoy['IMGONGLET1']}
                        listImg={ValuesQuelisoy['GALLERY']}
                    />
                    <ContentImgRight
                        title={ValuesQuelisoy['ONGLET2']}
                        text={ValuesQuelisoy['TEXTONGLET2']}
                        img={ValuesQuelisoy['IMGONGLET2']}
                    />
                </div>
                <div className={"w-full flex flex-col bg-opacity-75 bg-[#022855] "}>
                    <Bandeau/>
                    <AskInfos
                        lat={ValuesQuelisoy['LAT']}
                        long={ValuesQuelisoy['LONG']}
                        country={ValuesQuelisoy['ADDRESSCOUNTRY']}
                        adress={ValuesQuelisoy['ADDRESS']}
                        currentPage={ValuesQuelisoy['TITLE']}
                    />
                    <Footer/>
                </div>
            </div>
        </>
    );
}