import {Footer} from "../../Components/footer";
import React from "react";
import {ContentAboutUs} from "./components";

export default function AboutUs() {
    return (
        <div className={"w-full min-h-screen flex flex-col items-center justify-between gap-5"}>
            <ContentAboutUs />
            <Footer/>
        </div>
    );
}
