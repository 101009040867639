import React from "react";
import Slider from "react-slick";

export function BackgroundMultipleImg() {
    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        className: "overflow-hidden w-[100vw] h-[100vh] p-0 slick-slider-custom",
        fade: true,
        touchMove: false
    };
    return (
            <Slider {...settings}>
                <div className={"h-[100vh] w-[100vw] bg-cover bg-center bg-[url('../public/Accueil/1.jpeg')]"} />
                <div className={"h-[100vh] w-[100vw] bg-cover bg-center bg-[url('../public/Accueil/2.jpg')]"} />
                <div className={"h-[100vh] w-[100vw] bg-cover bg-center bg-[url('../public/Accueil/3.jpg')]"} />
                <div className={"h-[100vh] w-[100vw] bg-cover bg-center bg-[url('../public/Accueil/4.jpg')]"} />
                <div className={"h-[100vh] w-[100vw] bg-cover bg-center bg-[url('../public/Accueil/5.jpg')]"} />
                {/*<div className={"h-[100vh] w-[100vw] bg-cover bg-center bg-[url('../public/Accueil/6.jpg')]"} />*/}
                <div className={"h-[100vh] w-[100vw] bg-cover bg-center bg-[url('../public/Accueil/7.jpg')]"} />
            </Slider>
    );
}
