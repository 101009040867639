import {ComposantProject} from "../components";
import React from "react";
import {AskInfos, Bandeau, ContentImgLeft, ContentImgRight, ProgramTitle} from "./components";
import {Footer} from "../../../Components/footer";
import {BackgroundMultipleImg} from "../../../Components/background";

export const ValuesAlicia: Record<string, any> = {
    'TITLE': 'Résidence Alicia',
    'SOUSTITLE': '51 appartements au sein d\'un quartier dynamique de Lorient, proximité immédiate des commerces, services et transports',
    'LOCALISATION': 'Lorient',
    'LOGO': '../Programs/Alicia/2.jpg',
    'STATUT': 'EN CONSTRUCTION',
    'URL': '/nos-programmes/alicia',
    'ADDRESS': 'Alice Coleno',
    'ADDRESSCOUNTRY': '56100 LORIENT',
    'LAT': 47.755862090942266,
    'LONG': -3.3881942539698318,
    'ONGLET1': 'La résidence',
    'TEXTONGLET1': <><p>La résidence regroupera 51 appartements du T1 au T5, bénéficiant tous d'une terrasse ou d'un
        balcon. </p>
        <ul className={"list-disc list-inside"}>
            <li>Situé à 10 min en voiture du centre-ville</li>
            <li>À proximité des commerces et écoles</li>
            <li>Transport en commun à moins de 5 min</li>
            <li>Stationnements extérieurs disponible</li>
        </ul>
    </>,
    'IMGONGLET1': '../Programs/Alicia/2.jpg',
    'ONGLET2': 'Lorient (56)',
    'TEXTONGLET2': 'La résidence Alicia est située proche de toutes commodités dans le quartier de Kerfichant, jouxtant le quartier universitaire et la zone commerciale de Keryado. Ce quartier regroupe un grand nombre de services et d’infrastructures (commerces, éducation, santé, sports, loisirs) pour améliorer le cadre de vie de ses résidents.',
    'IMGONGLET2': '../Villes/lorient.jpg',
    'GALLERY': new Set(['../Programs/Alicia/1.jpg', '../Programs/Alicia/2.jpg', '../Programs/Alicia/3.jpg', '../Programs/Alicia/4.jpg'])
};

export const PreviewAlicia = ({detailHover}: { detailHover: boolean }) => {
    return (
        <ComposantProject
            title={ValuesAlicia['TITLE']}
            statut={ValuesAlicia['STATUT']}
            localisation={ValuesAlicia['LOCALISATION']}
            logo={ValuesAlicia['LOGO']}
            url={ValuesAlicia['URL']}
            detailHover={detailHover}
        />
    );
}

export const PageAlicia = () => {
    return (
        <>
            <BackgroundMultipleImg/>
            <div className={"z-50 w-full min-h-screen flex flex-col justify-between items-center pt-10"}>
                <div className={"w-full flex flex-col pt-20 bg-white"}>
                    <ProgramTitle
                        title={ValuesAlicia['TITLE']}
                        sousTitle={ValuesAlicia['SOUSTITLE']}
                    />
                    <ContentImgLeft
                        title={ValuesAlicia['ONGLET1']}
                        text={ValuesAlicia['TEXTONGLET1']}
                        img={ValuesAlicia['IMGONGLET1']}
                        listImg={ValuesAlicia['GALLERY']}
                    />
                    <ContentImgRight
                        title={ValuesAlicia['ONGLET2']}
                        text={ValuesAlicia['TEXTONGLET2']}
                        img={ValuesAlicia['IMGONGLET2']}
                    />
                </div>
                <div className={"w-full flex flex-col bg-opacity-75 bg-[#022855] "}>
                    <Bandeau/>
                    <AskInfos
                        lat={ValuesAlicia['LAT']}
                        long={ValuesAlicia['LONG']}
                        country={ValuesAlicia['ADDRESSCOUNTRY']}
                        adress={ValuesAlicia['ADDRESS']}
                        currentPage={ValuesAlicia['TITLE']}
                    />
                    <Footer/>
                </div>
            </div>
        </>
    );
}
