import React from "react";
import {MyMapGoogleForProgram} from "../../../Components/map";
import LightGallery from 'lightgallery/react';
import {FileTextIcon, ImagesIcon} from "lucide-react";

import {FormContact} from "../../../Components/form-contact";

export const ProgramTitle = ({title, sousTitle}: { title: string, sousTitle?: string }) => {
    return (
        <div className={"flex flex-col items-start gap-4"}>
            <h2 className={"font-semibold lg:text-5xl md:text-4xl text-3xl text-center p-1"}>{title}</h2>
            <div className={"bg-[#022855] h-1 w-52 rounded-full"}></div>
            {sousTitle && <h3 className={"font-semibold text-xl text-center text-[#022855]"}>{sousTitle}</h3>}
        </div>
    )
        ;
}

export const ContentImgLeft = ({title, text, img, listImg, plaquette}: {
    title: string,
    text: string,
    img: string,
    listImg?: Set<string>,
    plaquette?: string
}) => {
    return (
        <div className={"py-4 md:grid md:grid-cols-2 items-center bg-white"}>
            <div>
                <img src={img} alt={''} className={"h-96 w-full object-cover"}/>
            </div>
            <div className={"p-4 flex-col flex gap-3"}>
                <h3 className={"font-semibold text-2xl"}>{title}</h3>
                <div className={"bg-[#022855] h-1 w-20 rounded-full"}></div>
                <div className={"text-left"}>
                    {text}
                </div>
                <div className={"inline-flex gap-2"}>
                    {listImg && <Gallery listImg={listImg}/>}
                    {plaquette && (
                        <a
                            className="gallery-item"
                            href={plaquette}
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            <button
                                className={"bg-[#022855] px-4 py-2 text-white rounded-b-lg hover:bg-[#283b57]/90 inline-flex gap-2 items-center"}>Plaquette <FileTextIcon
                                className={"h-4 w-4"}/></button>
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
}

export const ContentImgRight = ({title, text, img}: { title: string, text: string, img: string }) => {
    return (
        <div className={"py-4 md:grid md:grid-cols-2 items-center bg-[#022855]"}>
            <div className={"p-4 flex-col flex gap-3 items-end flex-grow"}>
                <h3 className={"font-semibold text-2xl text-white"}>{title}</h3>
                <div className={"bg-white h-1 w-20 rounded-full"}></div>
                <div className={"text-right text-white"}>
                    {text}
                </div>
            </div>
            <div>
                <img src={img} alt={''} className={"h-96 w-full object-cover"}/>
            </div>
        </div>
    )
        ;
}

export function Bandeau() {
    return (<div className={"bg-[#022855] h-[10vh]"}/>);
}

export const AskInfos = ({adress, country, lat, long, currentPage}: {
    adress: string,
    country: string,
    lat: number,
    long: number,
    currentPage: string
}) => {
    return (
            <div className={"flex flex-col items-center m-4 "}>
                <div
                    className={"w-full justify-center items-center"}>
                    <div className={"flex flex-col justify-center items-center gap-4"}>
                        <div className={"lg:grid lg:grid-cols-3 md:grid md:grid-cols-2 w-full"}>
                            <div
                                className={"bg-[#022855] md:m-4 p-4 flex flex-col items-center gap-3 w-full"}>
                                <FormContact isLarge={false} currentPage={currentPage}/>
                            </div>
                            <div className={"lg:col-span-2 md:m-4 p-4 flex flex-col w-full"}>
                                <div className={"flex flex-col p-4 items-center text-white"}>
                                    <h3 className={"font-primary text-lg"}>Adresse du bien</h3>
                                    <span className={"text-sm"}>{adress}</span>
                                    <span className={"text-sm"}>{country}</span>
                                </div>
                                <div>
                                    <MyMapGoogleForProgram lat={lat} long={long}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export const Gallery = ({listImg}: { listImg: Set<string> }) => {
    const tabSrcImg: string[] = Array.from(listImg);
    const firstSrcImg = tabSrcImg[0];

    const otherSrcImg = tabSrcImg.splice(1, tabSrcImg.length);

    return (
        <div>
            <LightGallery mode="lg-fade">
                <a
                    className="gallery-item"
                    data-src={firstSrcImg}
                >
                    <button
                        className={"bg-[#022855] px-4 py-2 text-white rounded-b-lg hover:bg-[#283b57]/90 inline-flex gap-2 items-center"}>Galerie <ImagesIcon
                        className={"h-4 w-4"}/></button>
                </a>
                {otherSrcImg.map((srcImg, index) => {
                    return (
                        <a
                            key={`${index}_${srcImg}`}
                            className="gallery-item"
                            data-src={srcImg}
                        ></a>
                    );
                })}
            </LightGallery>
        </div>
    );
}
