import {ComposantProject} from "../components";
import React from "react";
import {AskInfos, Bandeau, ContentImgLeft, ContentImgRight, ProgramTitle} from "./components";
import {Footer} from "../../../Components/footer";
import {BackgroundMultipleImg} from "../../../Components/background";

export const ValuesLTPH: Record<string, any> = {
    'TITLE': 'Les Terrasses de Port Haliguen',
    'SOUSTITLE': 'Appartements privilégiés, pleine vue mer, au pied de Port-Haliguen et de la plage de Porigo à Quiberon. Résidence livrée en 2016.',
    'STATUT': 'RESIDENCE LIVREE',
    'URL': '/nos-programmes/ltph',
    'ONGLET1': 'La résidence',
    'LOGO': '../Programs/LTPH/1.jpg',
    'LOCALISATION': 'Quiberon',
    'ADDRESS': '6 Bd des Émigrés',
    'ADDRESSCOUNTRY': '56170 QUIBERON',
    'LAT': 47.485995037601775,
    'LONG': -3.1003413227471164,
    'TEXTONGLET1': 'Une résidence haut de gamme offrant quelques appartements avec une vue privilégiée sur le Port Haliguen.\n\nPort Haliguen est une escale recherchée car idéalement situé à l\'entrée de la Baie de Quiberon. Le centre-ville et tous ses commerces, sont accessibles à pied en quelques minutes.',
    'IMGONGLET1': '../Programs/LTPH/1.jpg',
    'ONGLET2': 'Quiberon (56)',
    'TEXTONGLET2': 'Située en Bretagne, dans le département du Morbihan (56), la ville de Quiberon est considérée comme une presqu’île.\n' +
        '\nQuiberon est une station balnéaire vivante toute l’année mais reculée des grandes villes et loin de la pollution. Elle est bordée par une côte sauvage spectaculaire à l’Ouest et de belles plages de sable fin à l’Est.\n' +
        '\nLe charme de cette ville, la tranquillité, l’espace, les paysages, les produits locaux, les commerces de proximité… C’est le lieu idéal pour les amoureux de nature et des sports nautiques.\n' +
        '\nQuiberon est à proximité des grandes villes comme Vannes, Lorient ou encore Auray, toutes à moins d’une heure de route. ',
    'IMGONGLET2': '../Villes/quiberon.jpg',
    'GALLERY': new Set(['../Programs/LTPH/1.jpg'
    ])
};

export const PreviewLTPH = ({detailHover}: { detailHover: boolean }) => {
    return (
        <ComposantProject
            title={ValuesLTPH['TITLE']}
            statut={ValuesLTPH['STATUT']}
            localisation={ValuesLTPH['LOCALISATION']}
            logo={ValuesLTPH['LOGO']}
            url={ValuesLTPH['URL']}
            detailHover={detailHover}
        />
    );
}

export const PageLTPH = () => {
    return (
        <>
            <BackgroundMultipleImg/>
            <div className={"z-50 w-full min-h-screen flex flex-col justify-between items-center pt-10"}>
                <div className={"w-full flex flex-col pt-20 bg-white"}>
                    <ProgramTitle
                        title={ValuesLTPH['TITLE']}
                        sousTitle={ValuesLTPH['SOUSTITLE']}
                    />
                    <ContentImgLeft
                        title={ValuesLTPH['ONGLET1']}
                        text={ValuesLTPH['TEXTONGLET1']}
                        img={ValuesLTPH['IMGONGLET1']}
                        listImg={ValuesLTPH['GALLERY']}
                    />
                    <ContentImgRight
                        title={ValuesLTPH['ONGLET2']}
                        text={ValuesLTPH['TEXTONGLET2']}
                        img={ValuesLTPH['IMGONGLET2']}
                    />
                </div>
                <div className={"w-full flex flex-col bg-opacity-75 bg-[#022855] "}>
                    <Bandeau/>
                    <AskInfos
                        lat={ValuesLTPH['LAT']}
                        long={ValuesLTPH['LONG']}
                        country={ValuesLTPH['ADDRESSCOUNTRY']}
                        adress={ValuesLTPH['ADDRESS']}
                        currentPage={ValuesLTPH['TITLE']}
                    />
                    <Footer/>
                </div>
            </div>
        </>
    );
}