import {CarrouselImg, Contact, NewCarouselSpacing} from "./components";
import {Footer} from "../../Components/footer";

export default function Home() {
    return (
        <div className="w-screen min-h-screen flex flex-col overflow-y-hidden">
            <CarrouselImg />
            <div className={"z-50 w-full"}>
                <NewCarouselSpacing />
                <Contact />
                <Footer/>
            </div>
        </div>
    );
}
