import React from "react";
import {Image} from "react-bootstrap";

export function ContentLegalNotices() {
  return (
      <div
          className="flex flex-col items-center gap-5 w-full h-full pt-10 ">
        <div className="flex flex-col items-center gap-4 w-full pt-20">
          <h2 className={"p-1 font-semibold lg:text-5xl md:text-4xl text-3xl text-center text-[#022855]"}>Les mentions légales</h2>
          <div className={"bg-[#022855] h-1 w-52 rounded-full"}></div>
        </div>
        <div className={"grid grid-cols-1 md:grid-cols-2 gap-5 md:w-[80vw] sm:w-[90vw]"}>
          <div className={"flex flex-col gap-5"}>
            <div className={"flex flex-col gap-3 "}>
              <h3 className={"font-semibold text-2xl text-[#022855] text-left"}>Informations
                générales</h3>
              <div className={" flex flex-col text-justify gap-2"}>
                <span>Au cours de ces dernières années, Horizon Grand Large a collaboré avec de nombreux cabinets d'Architecture à la réputation bien établie.</span>
                <span>Chaque programme est conçu en osmose avec son environnement, de sorte qu’il s’intégrera parfaitement au paysage qui l’entoure. Notre philosophie est à l'opposé des grands groupes nationaux dont les maîtres mots sont « bilan financier, volume, rendement... ». Nous refusons les produits formatés, banalisés, sans identité. Chaque projet étant unique  à nos yeux, il est étudié avec une attention particulière qui permet de lui donner sa spécificité. </span>
                <span>Nous recherchons la meilleure exposition pour que la lumière inonde vos journées. Nous concevons des espaces généreux et fonctionnels pour optimiser votre futur lieu de vie.</span>
              </div>
            </div>
            <div className={"flex flex-col gap-3"}>
              <h3 className={"font-semibold text-2xl text-[#022855] text-left"}>Informations
                techniques</h3>
              <div className={"text-justify"}>
                <span>Il est rappelé que le secret des correspondances n'est pas garanti sur le réseau Internet et qu'il appartient à chaque utilisateur d'Internet de prendre toutes les mesures appropriées de façon à protéger ses propres données et/ou logiciels de la contamination d'éventuels virus circulant sur Internet.</span>
              </div>
            </div>
            <div className={"flex flex-col gap-3"}>
              <h3 className={"font-semibold text-2xl text-[#022855] text-left"}>Charte
                éditoriale </h3>
              <div className={"text-justify flex flex-col gap-2"}>
                <span>Le site https://www.horizongrandlarge.com est un site de présentation des services proposés par SARL Horizon Grand Large. Il est complété par des outils de simulation. Les informations fournies dans ce site ne sont données qu'à titre indicatif.</span>
                <span>Adresse : 8 Rue Auguste Nayel, 56100 Lorient</span>
                <span>Téléphone : 02 97 11 92 69</span>
                <span>E-mail : contact@groupe-imwest.com</span>
              </div>
            </div>
          </div>
          <div className={"flex flex-col gap-5"}>
            <div className={"flex flex-col gap-3"}>
              <h3 className={"font-semibold text-2xl text-[#022855] text-left"}>Données
                personnelles </h3>
              <div className={"text-justify flex flex-col gap-2"}>
                <span>Les informations que vous saisirez seront enregistrées par SARL Horizon Grand Large, elles seront analysées et transmises aux différents services concernés pour leur prise en charge. Elles pourront, en outre, être utilisées par SARL Horizon Grand Large pour des sollicitations commerciales.</span>
                <span>Si vous ne le souhaitez pas, veuillez nous le signifier en écrivant à l'adresse précisée ci-après. Vous disposez d'un droit d'accès, de modification, de rectification et de suppression concernant les données collectées sur ce site, dans les conditions prévues par la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés. Pour l'exercer, adressez-vous par courrier au gestionnaire du site, à l'adresse suivante : 8 Rue Auguste Nayel, 56100 Lorient</span>
              </div>
            </div>
            <div className={"flex flex-col gap-3"}>
              <h3 className={"font-semibold text-2xl text-[#022855] text-left"}>Droits
                d'auteurs </h3>
              <div className={"text-justify"}>
                  <span>La reproduction ou représentation, intégrale ou partielle, des pages, des données et de tout autre élément constitutif au site, par quelque procédé ou support que ce soit, est interdite et constitue sans autorisation une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur.
                  </span>
              </div>
            </div>
            <div className={"flex flex-col gap-3"}>
              <h3 className={"font-semibold text-2xl text-[#022855] text-left"}>Photographies ©</h3>
              <div className={"text-justify"}>
                <span>Fotolia</span>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}