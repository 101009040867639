import {ComposantProject} from "../components";
import React from "react";
import {AskInfos, Bandeau, ContentImgLeft, ContentImgRight, ProgramTitle} from "./components";
import {Footer} from "../../../Components/footer";
import {BackgroundMultipleImg} from "../../../Components/background";

export const ValuesLTL: Record<string, any> = {
    'TITLE': 'Les Terrasses de Locqueltas',
    'SOUSTITLE': 'Une résidence de grand standing offrant seulement 6 appartements livrés en 2022.',
    'STATUT': 'RESIDENCE LIVREE',
    'URL': '/nos-programmes/ltl',
    'LOCALISATION': 'Larmor-Plage',
    'LOGO': '../Programs/LTL/1.jpg',
    'ADDRESS': '9 Imp. Eugène le Coupanec',
    'ADDRESSCOUNTRY': '56260 LARMOR-PLAGE',
    'LAT': 47.70360296271375,
    'LONG': -3.3914279153433893,
    'ONGLET1': 'La résidence',
    'TEXTONGLET1': 'C\'est dans le quartier prisé de Locqueltas, à Larmor-Plage, que se situe cette résidence, à proximité immédiate du bourg et des plages.',
    'IMGONGLET1': '../Programs/LTL/2.jpg',
    'ONGLET2': 'Larmor-Plage (56)',
    'TEXTONGLET2':
        <p>
            Idéalement située en Bretagne Sud, jouxtant deux des quatre grandes villes du Morbihan, aux portes de
            Lorient, Larmor-Plage jouit d'une image séduisante. Prisée des amoureux de la Bretagne, réputée pour son
            charme authentique et la beauté de ses plages, Larmor-Plage a de quoi faire rêver : la mer, le port de
            plaisance, les restaurants, les commerces, tous les services à proximité immédiate et avec pour horizon
            l'ile de Groix.
            <br/>
            C'est ici que nous vous invitons à jeter l'ancre, un lieu unique de calme et de détente, ce mélange de
            bien-être et d'authencité ou la douceur de vivre s'apprécie pour les vacances ou tout au long de l'année.
        </p>,
    'IMGONGLET2': '../Villes/larmor.jpg',
    'GALLERY': new Set(['../Programs/LTL/1.jpg', '../Programs/LTL/2.jpg'])
};

export const PreviewLTL = ({detailHover}: { detailHover: boolean }) => {
    return (
        <ComposantProject
            title={ValuesLTL['TITLE']}
            statut={ValuesLTL['STATUT']}
            localisation={ValuesLTL['LOCALISATION']}
            logo={ValuesLTL['LOGO']}
            url={ValuesLTL['URL']}
            detailHover={detailHover}
        />
    );
}

export const PageLTL = () => {
    return (
        <>
            <BackgroundMultipleImg/>
            <div className={"z-50 w-full min-h-screen flex flex-col justify-between items-center pt-10"}>
                <div className={"w-full flex flex-col pt-20 bg-white"}>
                    <ProgramTitle
                        title={ValuesLTL['TITLE']}
                        sousTitle={ValuesLTL['SOUSTITLE']}
                    />
                    <ContentImgLeft
                        title={ValuesLTL['ONGLET1']}
                        text={ValuesLTL['TEXTONGLET1']}
                        img={ValuesLTL['IMGONGLET1']}
                        listImg={ValuesLTL['GALLERY']}
                    />
                    <ContentImgRight
                        title={ValuesLTL['ONGLET2']}
                        text={ValuesLTL['TEXTONGLET2']}
                        img={ValuesLTL['IMGONGLET2']}
                    />
                </div>
                <div className={"w-full flex flex-col bg-opacity-75 bg-[#022855] "}>
                    <Bandeau/>
                    <AskInfos
                        lat={ValuesLTL['LAT']}
                        long={ValuesLTL['LONG']}
                        country={ValuesLTL['ADDRESSCOUNTRY']}
                        adress={ValuesLTL['ADDRESS']}
                        currentPage={ValuesLTL['TITLE']}
                    />
                    <Footer/>
                </div>
            </div>
        </>
    );
}