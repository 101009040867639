import {Navigation} from "../Components/menu";
import {Outlet} from "react-router-dom";

const MainLayout = () => {
    return (
        <div
            className={'flex flex-col items-center text-black min-h-screen duration-300 ease-in-out'}
        >
            <Navigation/>
            <Outlet/>
        </div>
    );
};

export default MainLayout;